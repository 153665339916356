<template>
  <NotificationGroup :position="position" :group="group">
    <div
      class="fixed inset-x-0 z-[1000] m-auto flex max-w-[440px] items-start justify-center p-8 px-4"
      :class="position === 'top' ? 'top-0' : 'bottom-0'">
      <div>
        <Notification
          v-slot="{ notifications, close }"
          enter="transform ease-in-out duration-200 transition"
          :enter-from="
            isBottomNotifi
              ? 'translate-y-[120px] opacity-70'
              : 'translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-4'
          "
          :enter-to="isBottomNotifi ? 'translate-y-0 opacity-100' : 'translate-y-0 opacity-100 sm:translate-x-0'"
          :leave="isBottomNotifi ? 'transition ease-in duration-200' : 'transition ease-in duration-500'"
          :leave-from="isBottomNotifi ? 'opacity-100 translate-y-0' : 'opacity-100'"
          :leave-to="isBottomNotifi ? 'opacity-70 translate-y-32' : 'opacity-0'"
          move="transition">
          <div
            v-for="(notification, index) in notifications"
            v-show="isBottomNotifi ? index === notifications.length - 1 : true"
            :key="notification.id"
            data-test="notification"
            class="mx-auto flex w-full items-start justify-between overflow-hidden rounded-xl"
            :class="{
              'bg-red-100 p-16': notification.type == 'error',
              'bg-dark-500 p-12': notification.type == 'success',
              'mt-[64px]': index === 0 && position === 'top',
              'mt-8': index > 0 && position === 'top',
              'mb-8': isBottomNotifi,
            }">
            <div
              class="flex items-center justify-center"
              :class="{
                'bg-red-100': notification.type == 'error',
                'bg-dark-500': notification.type == 'success',
              }">
              <template v-if="notification.type == 'error'">
                <g-base-icon name="error-bold" width="20" height="20" class="text-red-400"></g-base-icon>
              </template>
              <!-- <template v-if="notification.type == 'success'">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9 17.75C13.8325 17.75 17.75 13.8325 17.75 9C17.75 4.16751 13.8325 0.25 9 0.25C4.16751 0.25 0.25 4.16751 0.25 9C0.25 13.8325 4.16751 17.75 9 17.75ZM14.1893 6.69455C14.4348 6.45192 14.4372 6.0562 14.1945 5.81068C13.9519 5.56516 13.5562 5.56282 13.3107 5.80545L7.42558 11.6213L5.43933 9.65833C5.19381 9.4157 4.79809 9.41803 4.55546 9.66355C4.31283 9.90906 4.31516 10.3048 4.56067 10.5474L6.98624 12.9445C7.2297 13.1852 7.62142 13.1852 7.86489 12.9445L14.1893 6.69455Z"
                    fill="#00A042" />
                </svg>
              </template> -->
            </div>
            <div class="flex-1 px-8">
              <span
                class="text-16 font-semibold"
                :class="{
                  'text-red-400': notification.type == 'error',
                  'text-white': notification.type == 'success',
                }">
                <!-- <template v-if="notification.type == 'error'">
                  {{ notification.title || 'Error' }}
                </template> -->
                <!-- <template v-if="notification.type == 'success'">
                  {{ notification.title || 'Success' }}
                </template> -->
              </span>
              <p
                class="text-14 font-regular"
                :class="{
                  '!text-red-400': notification.type == 'error',
                  'text-light-200V2': notification.type == 'success',
                }"
                v-html="notification.text"></p>
            </div>

            <div class="flex h-24 w-24 cursor-pointer items-center justify-center" @click="close(notification.id)">
              <g-base-icon
                name="close-bold"
                width="20"
                height="20"
                view-box="0 0 20 20"
                :class="notification.type === 'success' ? 'text-light-200V2' : 'text-red-400'"></g-base-icon>
            </div>
          </div>
        </Notification>
      </div>
    </div>
  </NotificationGroup>
</template>

<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    position?: 'top' | 'bottom';
    group?: string;
  }>(),
  {
    position: 'top',
  },
);
const isBottomNotifi = props.position === 'bottom';
</script>
<style scoped></style>
